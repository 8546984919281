import request from '@/utils/request'

export function getClientVisits(params) {
  return request({
    method: 'get',
    url: 'client-visit',
    params
  })
}

export function clientVisitFinished(data) {
  return request({
    method: 'post',
    url: 'client-visit/finished',
    data
  })
}

export function getClientVisitHistory(params) {
  return request({
    method: 'get',
    url: 'client-visit/history',
    params
  })
}
