<template>
  <div>
    <my-nav-bar
      title="拜访历史"
      left-text="返回"
      left-arrow
    />
    <van-sticky offset-top="1.22667rem">
      <van-cell title="拜访日期" :value="listQuery.date" is-link center @click="showPopup = true" />
    </van-sticky>
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list ref="list" v-model="loading" :finished="finished" @load="getList">
        <div v-for="(clientVisit, index) in list" :key="index" class="visit-item">
          <van-cell title="计划拜访日期" :value="clientVisit.plan_date" />
          <van-cell title="拜访客户" :value="clientVisit.client.name" />
          <van-cell value-class="client-address" title="客户地址" :value="clientVisit.client.address" />
          <van-cell title="拜访人" :value="clientVisit.employee.name" />
          <van-cell title="完成时间" :value="clientVisit.finished_at" />
          <van-cell title="拜访内容" :label="clientVisit.content" />
        </div>
      </van-list>
    </div>
    <van-popup v-model="showPopup" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @cancel="showPopup = false"
        @confirm="handleDatePickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { toDateString } from '@/utils'
import { getClientVisitHistory } from '@/api/client-visit'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0,
        date: ''
      },
      currentDate: new Date(),
      showPopup: false,
      loading: false,
      finished: false,
      list: [],
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getClientVisitHistory(this.listQuery).then(res => {
        this.list = res.data.list
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
      })
    },
    handleDatePickerConfirm(date) {
      this.listQuery.date = toDateString(date)
      this.showPopup = false
      this.refresh()
    },
    refresh() {
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    }
  }
}
</script>

<style lang="scss" scoped>
.visit-item {
  padding: 10px;
  padding-bottom: 0;
}
.visit-item:last-child {
  padding-bottom: 10px;
}
.client-address {
  flex: 3;
}
</style>
