var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "拜访历史",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-sticky', {
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "拜访日期",
      "value": _vm.listQuery.date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showPopup = true;
      }
    }
  })], 1), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (clientVisit, index) {
    return _c('div', {
      key: index,
      staticClass: "visit-item"
    }, [_c('van-cell', {
      attrs: {
        "title": "计划拜访日期",
        "value": clientVisit.plan_date
      }
    }), _c('van-cell', {
      attrs: {
        "title": "拜访客户",
        "value": clientVisit.client.name
      }
    }), _c('van-cell', {
      attrs: {
        "value-class": "client-address",
        "title": "客户地址",
        "value": clientVisit.client.address
      }
    }), _c('van-cell', {
      attrs: {
        "title": "拜访人",
        "value": clientVisit.employee.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "完成时间",
        "value": clientVisit.finished_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "拜访内容",
        "label": clientVisit.content
      }
    })], 1);
  }), 0)], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPopup,
      callback: function callback($$v) {
        _vm.showPopup = $$v;
      },
      expression: "showPopup"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日"
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showPopup = false;
      },
      "confirm": _vm.handleDatePickerConfirm
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }